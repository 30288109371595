import React from 'react'
import View from "../framework/alto/ui/view";
import LabelView from "../framework/alto/ui/label_view";
import { Link } from 'gatsby'
import css from './navigation.module.css';

let NavigationView = () => {

    const style = {
        textDecoration: 'none',
    }

    const activeStyle = {
        color: 'red',
    }

    return (
        <View className={css.navigation}>
            <LabelView title={'Navigation'} className={css.header}/>
            <View className={css.underline}/>
            <Link to="/portfolio/" className={css.link} style={style} activeStyle={activeStyle} >portfolio</Link>
            <Link to="/articles/" className={css.link} style={style}  activeStyle={activeStyle} >articles</Link>
            <Link to="/contact/" className={css.link} style={style}  activeStyle={activeStyle} >contact</Link>
        </View>
    )

};

export default NavigationView;