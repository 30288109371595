import React, {Fragment} from 'react'
import NavigationView from "../components/navigation";
import css from "./contact.module.css";
import LabelView from "../framework/alto/ui/label_view";
import View from "../framework/alto/ui/view";
import "../common_styles/common.css";

const ContactPage = () => {
    return (
        <Fragment>
            <View className={css.underline}/>
            <View className={css.document}>
                <NavigationView/>
            </View>
            <View className={css.fullscreen}>
                <LabelView title={'chad.d.eubanks@gmail.com'} className={css.text}/>
            </View>
        </Fragment>
    )
}

export default ContactPage
